import React from 'react'
import Components from '../storyblok/Components'
import SbEditable from 'storyblok-react'
import config from '../../gatsby-config'

const loadStoryblokBridge = function (cb: any) {
  let sbConfigs = config.plugins.filter((item: any) => {
    return item.resolve === 'gatsby-source-storyblok'
  })
  let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}
  let script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`
  script.onload = cb
  document.getElementsByTagName('head')[0].appendChild(script)
}

const getParam = function (val: any) {
  var result = ''
  var tmp = []

  window.location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

class StoryblokEntry extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = { story: null, header: { content: {} } }
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: getParam('path'),
        version: 'draft',
      },
      (data) => {
        this.setState({ story: data.story })
        this.loadHeader(data.story.lang)
        // this.loadFooter(data.story.lang)
      }
    )
  }

  loadHeader(lang: string) {
    const language = lang === 'default' ? '' : lang + '/'
    window.storyblok.get(
      {
        slug: `${language}global-navi`,
        version: 'draft',
      },
      (data: any) => {
        this.setState({ header: data.story })
      }
    )
  }

  loadFooter(lang: string) {
    const language = lang === 'default' ? '' : lang + '/'
    window.storyblok.get(
      {
        slug: `${language}global-navi`,
        version: 'draft',
      },
      (data: any) => {
        this.setState({ header: data.story })
      }
    )
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam('path') })

    let sb = window.storyblok

    sb.on(['change', 'published'], (payload) => {
      this.loadStory(payload)
    })

    sb.on('input', (payload: any) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )
        this.setState({ story: payload.story })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    if (this.state.story == null) {
      return <div />
    }

    let story = this.state.story
    let content = story.content
    if (content.component === 'header') {
      content = this.state.story
    }

    return (
      <SbEditable content={content}>
        {React.createElement(Components(story.content.component), {
          storyID: story.uuid,
          tags: story.tag_list,
          name: story.name,
          // article: article,
          slug: story.slug,
          isStartPage: story.is_startpage,
          full_slug: story.full_slug,
          date: story.created_at,
          blok: content,
          // footer: footer,
        })}
      </SbEditable>
    )
  }
}

export default StoryblokEntry
