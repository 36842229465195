require(`dotenv`).config({
  path: `.env`,
})

const ENVIRONMENT = process.env.GATSBY_ENVIRONMENT
  ? process.env.GATSBY_ENVIRONMENT
  : `development`
const STORYBLOK_TOKEN = process.env.GATSBY_STORYBLOK_TOKEN
const SITE_URL = process.env.GATSBY_SITE_URL

module.exports = {
  siteMetadata: {
    title: `Mästerbo`,
    description: ``,
    author: `@oridnary`,
    siteUrl: SITE_URL,
  },
  plugins: [
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-root-import`,
    `gatsby-plugin-sass`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Mästerbo`,
        short_name: `Mästerbo`,
        start_url: `/`,
        background_color: `#063136`,
        theme_color: `#063136`,
        display: `minimal-ui`,
        icon: `src/assets/images/favicon.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-source-storyblok`,
      options: {
        accessToken: STORYBLOK_TOKEN,
        homeSlug: `home`,
        version: ENVIRONMENT !== `production` ? `draft` : `published`,
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        excludes: [`/editor/*`],
      },
    },
    {
      resolve: `gatsby-plugin-robots-txt`,
      options: {
        host: SITE_URL,
        policy:
          ENVIRONMENT !== `production`
            ? [{ userAgent: `*`, disallow: `/` }]
            : [{ userAgent: `*`, allow: `/` }],
      },
    },
  ],
}
